module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-instagram-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"panmacmillan.com","short_name":"panmacmillan.com","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/pm_framework/assets/images/panmacmillan-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9c2bc0e2f951cbe5968e6633f2334d66"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N6SJQ6J","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"gtmAuth":"bW9egBmtWhf5eHgtTbJiLA","gtmPreview":"env-2","routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/@panmacmillan/gatsby-theme-panmacmillan/gatsby-browser.js'),
      options: {"plugins":[],"kontentProjectId":"bcd02f72-b50c-0179-8b4b-5e44f5340bd4","displayArticleFooterEmailSignUp":true,"disableArticlesBooksYouMayLike":true,"displayOneTrustFooterButton":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
